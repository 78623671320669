import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "jc-root",
  storage,
  reduxStore: ["authReducer"],
};
const rootReducer = combineReducers({
  authReducer,
});

export default persistReducer(persistConfig, rootReducer);
