import Cookies from 'js-cookie'
import { responseNotification } from '../../utils/notify'
import { LOGIN_REQUEST, LOGIN_SUCCESS } from './authType'
import decode from 'jwt-decode'

export const login = async (
	dispatch: (arg0: { type: string; payload?: any }) => void,
	{ email, password }: any
) => {
	try {
		dispatch({
			type: LOGIN_REQUEST,
		})

		const res = await fetch(`${process.env.REACT_APP_AUTH_API}/admin/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email,
				password,
			}),
		})

		const response1 = await res.json()
		const { token } = response1

		if (token) {
			const res2 = await fetch(
				`${process.env.REACT_APP_AUTH_API}/admin/permissions`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				}
			)

			const response2 = await res2.json()
			const { serviceGroups } = response2

			console.log('Res:', response1, response2)

			if (serviceGroups?.length) {
				const formatedServices = serviceGroups?.map(
					(item: {
						action: any
						serviceNameId: any
						serviceName: { name: any }
					}) => ({
						action: item.action,
						serviceNameId: item.serviceNameId,
						serviceName: item.serviceName?.name,
					})
				)

				Cookies.set('authToken', token, {
					secure: true,
					expires: (decode(token as string) as any)?.exp,
				})
				localStorage.setItem('services', JSON.stringify(formatedServices))

				dispatch({
					type: LOGIN_SUCCESS,
					payload: { ...response2, ...response1, type: 'JC_PARCEL' },
				})

				responseNotification(
					response2?.message?.replace('Operation', 'Login'),
					'success'
				)

				return true
			} else {
				responseNotification(
					response2?.message?.replace('No services allowed', 'Login'),
					'error'
				)

				return false
			}
		} else {
			responseNotification(response1?.message, 'error')
			return false
		}
	} catch (error) {
		console.log(error)
		return false
	}
}
