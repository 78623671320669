import { PageHeader } from "antd";

const BreadCrumb = ({ title, subTitle, extra }: any): any => {
  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        className="bg-gray-100 px-0"
        ghost={false}
        onBack={() => window.history.back()}
        title={title}
        subTitle={subTitle}
        extra={extra}
      />
    </div>
  );
};

export default BreadCrumb;
