import MainLayout from "../../components/Layouts";
import CompanyRequestList from "../../components/EcabRequest";
import { ReactElement } from "react";

const CompanyRequestPage = (): ReactElement => {
  return (
    <MainLayout>
      <CompanyRequestList />
    </MainLayout>
  );
};

export default CompanyRequestPage;
