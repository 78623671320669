import DriverDetails from '../../components/Delivery/DeliveryHero/Details'
import MainLayout from '../../components/Layouts'

function DriverDetailsPage() {
	return (
		<MainLayout>
			<DriverDetails />
		</MainLayout>
	)
}

export default DriverDetailsPage
