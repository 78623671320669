import { Layout, Menu } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { adminServices, isAllowedService } from '../../../utils/services'
import { SIDEBAR_COLLAPSE } from '../../../redux/auth/authType'
import LogoImg from '../../../images/logo.svg'
import IconImg from '../../../images/favicon.png'
const { Sider } = Layout
const { SubMenu } = Menu

const MainSidebar = (): ReactElement => {
	const { sidebarCollapse } = useSelector(state => (state as any)?.authReducer)
	// const [collapsed, setCollapsed] = useState(false);
	const dispatch = useDispatch()
	const pathname = window.location.pathname

	const setCollapsed = (value: boolean) => {
		dispatch({
			type: SIDEBAR_COLLAPSE,
			payload: {
				sidebarCollapse: value,
			},
		})
	}

	console.log('Collapsed', sidebarCollapse)

	return (
		<Sider
			width={230}
			collapsible
			collapsed={sidebarCollapse}
			onCollapse={value => {
				setCollapsed(value)
			}}
			// onCollapse={this.toggleCollapsed}
			// onBreakpoint={this.toggleCollapsed}
			// style={{
			//   overflow: "auto",
			//   height: "100vh",
			//   position: "fixed",
			//   left: 0,
			//   top: 0,
			//   bottom: 0,
			// }}
		>
			<div className='logo'>
				<Link to='/'>
					<img
						src={sidebarCollapse ? IconImg : LogoImg}
						alt='jachai parcel'
						className='logoImg'
					/>
				</Link>
			</div>

			<Menu
				theme='dark'
				defaultSelectedKeys={['']}
				mode='inline'
				// inlineCollapsed={this?.state?.collapsed}
				// openKeys={this.state.openKeys}
				// onOpenChange={this.onOpenChange}
			>
				{adminServices?.map((service, i) => {
					if (service?.sub && service?.sub?.length) {
						return (
							service?.sub?.find(item => isAllowedService(`${item?.name}`)) && (
								<SubMenu
									key={service?.path}
									icon={<FontAwesomeIcon icon={service?.icon} />}
									title={service?.name}
								>
									{service?.sub?.map(sub => {
										const actualPath = `${service?.path}${sub?.path}`
										return (
											!actualPath.includes(':') &&
											isAllowedService(`${sub?.name}`) && (
												<Menu.Item
													key={actualPath}
													icon={<FontAwesomeIcon icon={sub?.icon} />}
													className={pathname === actualPath ? 'active' : ''}
												>
													<Link to={actualPath}>{sub?.name}</Link>
												</Menu.Item>
											)
										)
									})}
								</SubMenu>
							)
						)
					} else {
						console.log(
							'isAllowedService:',
							service?.name,
							isAllowedService(`${service?.name}`)
						)
						return (
							isAllowedService(`${service?.name}`) && (
								<Menu.Item
									key={service?.path}
									icon={<FontAwesomeIcon icon={service?.icon} />}
									className={pathname === service?.path ? 'active' : ''}
								>
									<Link to={`${service?.path}`}>{service?.name}</Link>
								</Menu.Item>
							)
						)
					}
				})}

				{/* <Menu.Item
          icon={<FontAwesomeIcon icon={faCopy} />}
          className={pathname === "/requests" ? "active" : ""}
        >
          <Link to="/requests">Parcel Request</Link>
        </Menu.Item> */}
			</Menu>
		</Sider>
	)
}

export default MainSidebar
