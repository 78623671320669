import { Button } from 'antd'
import PropTypes from 'prop-types'
import { ReactElement } from 'react'
import styles from '../../styles/tailwind/Add.module.css'

const SubmitResetBtn = ({
	props,
	onClickSubmit,
	onClickReset,
	loading,
	disabled,
}: any): ReactElement => {
	return (
		<div className='border-t border-gray-100 mt-4 pt-5'>
			<Button
				// {...props}
				disabled={disabled}
				loading={loading}
				size='large'
				htmlType='submit'
				className={styles.submitBtn}
				onClick={onClickSubmit}
			>
				Submit
			</Button>
			<Button
				type='ghost'
				htmlType='button'
				size='large'
				onClick={onClickReset}
				className={styles.resetBtn}
			>
				Reset
			</Button>
		</div>
	)
}
SubmitResetBtn.propTypes = {
	onClick: PropTypes.func,
	onClickSubmit: PropTypes.func,
	onClickReset: PropTypes.func,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
}

export default SubmitResetBtn
