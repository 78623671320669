import { authenticateToken } from "../../../utils/auth";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Avatar, Button, Drawer, Empty, Switch } from "antd";
import BreadCrumb from "../../Layouts/Breadcrumb";
import AddBtn from "../../common/AddBtn";
import { responseNotification } from "../../../utils/notify";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { getPage, getParamValue } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import SingleService from "./Details";
import AddService from "./AddService";

const UserServiceList = (): ReactElement => {
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [type, setType] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedType, setSelectedType] = useState(undefined);
  const [selectedTypeForEdit, setSelectedTypeForEdit] = useState(undefined);

  const [typeData, setTypeData] = useState<any>({
    loading: false,
    data: null,
  });

  const getParcelType = useCallback(async () => {
    setTypeData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_AUTH_API}`;
    axios
      .get(`${encodedUrl}/service/by-type?Type=JC_PARCEL`, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      })
      .then((res) => {
        setTypeData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setTypeData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, []);

  //
  const onStatusChange = async (id: any, val: boolean) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/service`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getParcelType();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getParcelType();
  }, [getParcelType]);

  const onClose = () => {
    getParcelType();
    setVisible(undefined);
    setSelectedType(undefined);
    setSelectedTypeForEdit(undefined);
  };

  return (
    <>
      <BreadCrumb
        title="Parcel Type"
        subTitle={`${typeData?.data?.totalElements || 0} Type(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {typeData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {typeData?.data?.services?.length ? (
                      typeData?.data?.services?.map((type: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                          onClick={() => setSelectedType(type)}
                        >
                          <td>{type?.name}</td>
                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={type?.isActive}
                              onChange={(val, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(type?.id, val);
                              }}
                            />
                          </td>
                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="bg-white"
                              onClick={() => setSelectedTypeForEdit(type)}
                            >
                              <EditOutlined />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...typeData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Service"
            : selectedTypeForEdit
            ? "Edit Service"
            : "Service Details"
        }
        placement="right"
        onClose={onClose}
        visible={visible || selectedType || selectedTypeForEdit}
        width={450}
      >
        {visible || selectedTypeForEdit ? (
          <AddService
            onCloseMethod={onClose}
            visibleData={selectedTypeForEdit}
          />
        ) : (
          <SingleService TypeDetails={selectedType} />
        )}
      </Drawer>
    </>
  );
};

export default UserServiceList;
