import { Avatar, Checkbox, Col, Divider, Radio, Row, Tag } from "antd";
import axios from "axios";
import { Key, ReactElement, useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../../utils/auth";
const CheckboxGroup = Checkbox;

const SingleGroup = ({ GroupDetails }: { GroupDetails: any }): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<any>();
  // --------------------------------
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  // --------------------------------

  const [groupDetails, setGroupDetails] = useState(undefined);
  const [groupData, setGroupData] = useState(undefined);
  const [groupServices, setGroupServices] = useState<any>({
    loading: false,
    list: [],
  });

  const [serviceOptions, setServiceOptions] = useState<any>({
    loading: false,
    list: [],
  });

  const getServiceOptions = useCallback(
    async (key?: string) => {
      setServiceOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
      return axios
        .get(
          `${encodedUri}/service/all` +
            (type ? `&type=${type}` : ``) +
            (key ? `&name=${key}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setServiceOptions({
            loading: false,
            list: res.data?.services?.map(
              (service: { name: any; id: any }) => ({
                label: service.name,
                value: service.id,
              })
            ),
          });
        })
        .catch((err) => {
          setServiceOptions({ loading: false, list: [] });
          console.error("Services: Error", err);
        });
    },
    [type]
  );

  const getServicesByGroup = useCallback(async (groupId?: string) => {
    setLoading(true);
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    return axios
      .get(`${encodedUri}/service-group/services-by-group?groupId=${groupId}`, {
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
      })
      .then((res: any) => {
        setGroupServices({
          loading: false,
          list: res?.data?.serviceGroups,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Services: Error", err);
      });
  }, []);

  // const onChange = (list) => {
  //   try {
  //     const temp = {} as any;
  //     list?.forEach((id) => {
  //       if (checkedList?.[id]) {
  //         temp[id] = checkedList?.[id];
  //       } else {
  //         temp[id] = "all";
  //       }
  //     });

  //     setCheckedList(temp);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    if (GroupDetails) {
      setGroupData(GroupDetails);
      getServicesByGroup(GroupDetails?.id);
    }
  }, [GroupDetails]);

  useEffect(() => {
    getServiceOptions();
  }, [getServiceOptions]);

  useEffect(() => {
    if (GroupDetails) {
      setGroupDetails(GroupDetails);
      getServicesByGroup(GroupDetails?.id);
    }
  }, [GroupDetails]);
  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Group Title</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GroupDetails?.name}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GroupDetails?.type?.replace("_", " ")}</span>
            </div>
          </div>
        </div>

        <Divider dashed orientation="left">
          Services
        </Divider>

        {groupServices?.list?.map(
          (serviceGroup: any, index: Key | null | undefined) => (
            <Tag
              color="#f50"
              key={index}
              className="bg-gradient-to-br from-green-500 to-green-700 text-xs font-bold uppercase text-white px-2 py-1 rounded-md mb-2 mr-2"
              closable
              // onClose={() => setGroupIds(grp?.group?.id || [])}
            >
              {serviceGroup?.serviceName?.name} | {serviceGroup?.action}
            </Tag>
          )
        )}
      </div>
      {console.log("checkedList", groupServices)}
    </>
  );
};

export default SingleGroup;
