import ParcelTypePage from "../pages/parcel-type";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "../components/common/404";
import Login from "../components/Login";
import RequestPage from "../pages/requests";
import DeliveryHeroPage from "../pages/delivery";
import { adminServices, isAllowedService } from "../utils/services";
import CompanyRequestPage from "../pages/Company";
import DashboardPage from "../pages";
import PrivateRoute from "./PrivateRoutes";
import useToken from "../utils/useToken";

const MainRoutes = (): any => {
  const { token, setToken } = useToken();
  // const navigate = useNavigate();

  console.log("router token", !token);

  return (
    <BrowserRouter>
      <Routes>
        {!token ? (
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
        ) : (
          <>
            {adminServices?.flatMap((service, index) => {
              if (service?.sub?.length) {
                return [
                  ...(service?.sub?.map((sub, i) => {
                    const ServiceElemnt = sub?.element;
                    return (
                      isAllowedService(`${sub.name}`) && (
                        <Route
                          key={i}
                          path={`${process.env.PUBLIC_URL}${service?.path}${sub?.path}`}
                          element={<ServiceElemnt />}
                        />
                      )
                    );
                  }) || []),
                ];
              } else {
                const ServiceElemnt = service?.element;
                return (
                  isAllowedService(service?.name) && (
                    <Route
                      key={index}
                      path={`${process.env.PUBLIC_URL}${service?.path}`}
                      element={<ServiceElemnt />}
                    />
                  )
                );
              }
            })}

            {/* <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<DashboardPage />}
            /> */}
       
            <Route path="*" element={<NotFound />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MainRoutes;
