import { Button, Form, Input, Select } from 'antd'
import axios from 'axios'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { authenticateToken } from '../../utils/auth'
import { responseNotification } from '../../utils/notify'
import styles from '../../styles/tailwind/Add.module.css'
import SubmitResetBtn from '../common/SubmitBtn'

const { Option } = Select

const AddUser = (
	{ UserDetails, onCloseMethod }: { UserDetails: any; onCloseMethod: any },
	props: any
): ReactElement => {
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [adminData, setAdminData] = useState<any>(undefined)
	const [adminGroup, setAdminGroup] = useState([])
	const [error, setError] = useState('')
	const [type, setType] = useState<any>()
	const [userGroup, setUserGroup] = useState([])

	const [groupOptions, setGroupOptions] = useState({
		loading: false,
		list: [],
	})

	// ******************************
	const fetchCorporateDetails = async () => {
		if (UserDetails?.email) {
			await fetch(
				`${process.env.REACT_APP_AUTH_API}/admin/details?email=${UserDetails?.email}`,
				{
					method: 'GET',
					headers: {
						Authorization: `Bearer ${authenticateToken()}`,
						'Content-Type': 'application/json',
					},
				}
			)
				.then(res => res.json())
				.then(res => {
					if (res.statusCode === 200) {
						form.resetFields()
						setAdminData(res?.admin)
						setAdminGroup(
							res?.groups?.map((group: { groupId: any }) => group?.groupId)
						)
					} else {
						responseNotification(res.message || 'something wrong', 'warning')
					}
				})
				.catch(err => {
					responseNotification(`${'Internal server error'} ${err}`, 'error')
					console.error('err', err)
				})
		}
	}

	// ******************************
	const getGroupOptions = useCallback(
		async (key?: string) => {
			setGroupOptions({ loading: true, list: [] })
			const encodedUri = `${process.env.REACT_APP_AUTH_API}`
			return axios
				.get(
					`${encodedUri}/group/all` +
						(type ? `&type=${type}` : ``) +
						(key ? `&name=${key}` : ``),
					{
						headers: {
							Authorization: `Bearer ${authenticateToken()}`,
						},
					}
				)
				.then(res => {
					setGroupOptions({
						loading: false,
						list: res.data?.groups?.map((group: { name: any; id: any }) => ({
							label: group.name,
							value: group.id,
						})),
					})
				})
				.catch(err => {
					setGroupOptions({ loading: false, list: [] })
					console.error('Groups: Error', err)
				})
		},
		[type]
	)

	const onSubmit = async (data: any) => {
		setLoading(true)

		const readyData = data && {
			name: data.name,
			email: data.email,
			password: data.password,
			groupActives: userGroup.map(item => ({
				groupId: item,
				isActive: true,
			})),
		}

		if (adminData) {
			await fetch(`${process.env.REACT_APP_AUTH_API}/admin`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...readyData,
					email: adminData?.email,
				}),
			})
				.then(res => res.json())
				.then(res => {
					setLoading(false)

					if (res.statusCode === 200) {
						responseNotification('Admin Updated Successfully', 'success')
						form.resetFields()
						if (onCloseMethod) {
							onCloseMethod()
						}
					} else if (res.status === 500) {
						responseNotification('Internal server error', 'error')
					} else {
						responseNotification(res.message || 'something wrong', 'warning')
					}
				})
				.catch(err => {
					setLoading(false)
					responseNotification(`${'Internal server error'} ${err}`, 'error')
					console.error('err', err)
				})
		} else {
			await fetch(`${process.env.REACT_APP_AUTH_API}/admin/register`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(readyData),
			})
				.then(res => res.json())
				.then(res => {
					setLoading(false)

					if (res.statusCode === 200) {
						responseNotification('Admin Create Successfully', 'success')
						form.resetFields()
						if (onCloseMethod) {
							onCloseMethod()
						}
					} else if (res.status === 500) {
						responseNotification('Internal server error', 'error')
					} else {
						responseNotification(res.message || 'something wrong', 'warning')
					}
				})
				.catch(err => {
					setLoading(false)
					responseNotification(`${'Internal server error'} ${err}`, 'error')
					console.error('err', err)
				})
		}
	}

	// ****************************************
	// const handleSearch = React.useMemo(() => {
	//   const loadOptions = (value: string, field: string) => {
	//     fetchRef.current += 1;
	//     const fetchId = fetchRef.current;

	//     if (fetchId !== fetchRef.current) {
	//       return;
	//     }
	//     if (value) {
	//       if (field === "group") getGroupOptions(value);
	//     }
	//   };

	//   return debounce(loadOptions, 800);
	// }, [getGroupOptions]);
	// ****************************************

	useEffect(() => {
		getGroupOptions()
	}, [getGroupOptions])

	useEffect(() => {
		if (adminData || adminGroup) {
			form.resetFields()
			// setLogoUrl(visibleData?.logo);
		}
	}, [adminData, adminGroup, UserDetails])

	useEffect(() => {
		if (UserDetails) {
			setAdminData(UserDetails)
		}
	}, [UserDetails])

	useEffect(() => {
		if (UserDetails) fetchCorporateDetails()
	}, [UserDetails])

	return (
		<div className=''>
			<Form
				name='control-hooks'
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				className='ant-form ant-form-vertical'
				onFinish={onSubmit}
				form={form} // like ref
				layout='vertical'
				// requiredMark="optional"
				autoComplete='off'
				initialValues={{
					...adminData,
					name: adminData?.fullName,
					groupId: adminGroup,
				}}
			>
				<Form.Item
					hasFeedback
					label='User Name'
					rules={[
						{
							required: true,
							message: 'Name is Required!',
						},
					]}
					name='name'
					initialValue={adminData?.fullName}
				>
					<Input id='name' type='text' placeholder='Enter Name' />
				</Form.Item>

				{!adminData && (
					<>
						<Form.Item
							hasFeedback
							label='Email'
							rules={[
								{
									required: true,
									message: 'Email is required!',
								},
								{
									validator: async (_, email) => {
										if (
											email &&
											!String(email)
												.toLowerCase()
												?.match(
													/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
												)
										) {
											return Promise.reject(new Error('Enter valid email'))
										}
									},
								},
							]}
							name='email'
						>
							<Input id='email' type='email' placeholder='Enter Email' />
						</Form.Item>

						<Form.Item
							hasFeedback
							label='Password'
							rules={[
								{
									required: true,
									message: 'Password is Required!',
								},
							]}
							name='password'
						>
							<Input id='password' type='password' placeholder='Enter Password' />
						</Form.Item>
					</>
				)}
				<Form.Item
					hasFeedback
					label={`Groups`}
					name='groupId'
					initialValue={adminGroup}
				>
					<Select
						defaultValue={adminGroup}
						allowClear
						showSearch
						placeholder='Select Group'
						optionFilterProp='children'
						onChange={val => {
							setUserGroup(val)
						}}
						filterOption={(input, option: any) =>
							option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >=
							0
						}
						mode='multiple'
					>
						{groupOptions?.list?.map((group: any, i) => (
							<Option value={group?.value} key={i}>
								{group?.label}
							</Option>
						))}
					</Select>
				</Form.Item>

				<SubmitResetBtn
					onClickReset={() => {
						form?.resetFields()
					}}
					disabled={loading || !!error}
					loading={loading}
				/>
			</Form>
		</div>
	)
}

export default AddUser
