import { ReactElement } from "react";
import MainLayout from "../../components/Layouts";
import UserManagementList from "../../components/Administration";
import UserServiceList from "../../components/Administration/UserService";
const UserServicePage = (): ReactElement => {
  return (
    <MainLayout>
      <UserServiceList />
    </MainLayout>
  );
};

export default UserServicePage;
