import ParcelTypeList from "../components/ParcelType";
import MainLayout from "../components/Layouts";

const ParcelTypePage = () => {
  return (
    <MainLayout>
      <ParcelTypeList />
    </MainLayout>
  );
};

export default ParcelTypePage;
