import MainLayout from "../../components/Layouts";

import { ReactElement } from "react";
import RequestList from "../../components/Parcel";

const RequestPage = (): ReactElement => {
  return (
    <MainLayout>
      <RequestList />
    </MainLayout>
  );
};

export default RequestPage;
