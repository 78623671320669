import { getUpdatedUrl } from "../../utils/";
import PropTypes from "prop-types";
import { ReactElement, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Button } from "antd";
const Pagination = ({
  first,
  totalElements,
  numberOfElements,
  limit,
  currentPageNumber,
}: any): ReactElement => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setitemsPerPage] = useState<number>(16);

  const [pageNumberLimit, setpageNumberLimit] = useState<number>(10);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState<number>(10);
  const [minPageNumberLimit, setminPageNumberLimit] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(currentPageNumber + 1 || 1);
    setitemsPerPage(limit || 16);
  }, [currentPageNumber, limit]);

  const handleClick = (event: any) => {
    event.preventDefault();
    setCurrentPage(Number(event.target.id));

    console.log("updated path: ", getUpdatedUrl(
      "page",
      `${event.target.id - 1 >= 0 ? event.target.id - 1 : 0}`
    ));

    navigate(
      getUpdatedUrl(
        "page",
        `${event.target.id - 1 >= 0 ? event.target.id - 1 : 0}`
      )
    );
  };

  const pages = [];
  const queryParams = new URLSearchParams(window.location.search);
  const page = queryParams.get("page");
  let start = 1;
  let end = 10;
  if (page === null) {
    start = 1;
    end = 10;
  } else {
    const floorValue = Math.floor(parseInt(page) / 10);
    const ceilValue = Math.ceil(parseInt(page) / 10);
    start = 10 * floorValue + 1;
    end = Math.min(10 * ceilValue, totalElements);
    if (start > end) end = Math.min(start + 9, totalElements);
    // setmaxPageNumberLimit(end);
    // setminPageNumberLimit(start);
  }

  for (let i = 1; i <= Math.ceil((totalElements || 0) / itemsPerPage); i++) {
    pages.push(i);
  }

  // const indexOfLastItem = currentPage * itemsPerPage
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const renderPageNumbers = pages.map((number) => {
    if (number >= start && number <= end) {
      return (
        <Link
          key={number}
          id={number.toString()}
          onClick={handleClick}
          to="#"
          aria-current="page"
          className={`z-10 relative inline-flex items-center px-3 py-1 border text-sm font-medium ${
            currentPage === number
              ? "bg-indigo-50 border-indigo-500 text-indigo-600"
              : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
          }`}
        >
          {number}
        </Link>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = (event: any) => {
    event.preventDefault();

    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    navigate(
      getUpdatedUrl(
        "page",
        `${currentPage + 1 - 1 >= 0 ? currentPage + 1 - 1 : 0}`
      )
    );
  };

  const handlePrevbtn = (event: any) => {
    event.preventDefault();

    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    navigate(
      getUpdatedUrl(
        "page",
        `${currentPage - 1 - 1 >= 0 ? currentPage - 1 - 1 : 0}`
      )
    );
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <Link
        className="relative inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        to="#"
        onClick={handleNextbtn}
      >
        {" "}
        &hellip;{" "}
      </Link>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <Link
        to="#"
        onClick={handlePrevbtn}
        className="relative inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
      >
        {" "}
        &hellip;{" "}
      </Link>
    );
  }

  useEffect(() => {
    if (first) {
      setCurrentPage(1);
    }
  }, [first]);

  return (
    <div
      className={
        totalElements &&
        "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-100 sm:px-6"
      }
    >
      {totalElements > itemsPerPage ? (
        <div className="flex-1 flex justify-between sm:hidden">
          <Button
            disabled={currentPage === pages[0] ? true : false}
            onClick={handlePrevbtn}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </Button>
          <Button
            onClick={handleNextbtn}
            disabled={currentPage === pages[pages.length - 1] ? true : false}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </Button>
        </div>
      ) : undefined}
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {totalElements ? (
            <p className="text-sm text-gray-700">
              Showing{" "}
              <span className="font-medium">
                {(currentPage - 1) * itemsPerPage + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {(currentPage - 1) * itemsPerPage + itemsPerPage > totalElements
                  ? totalElements
                  : (currentPage - 1) * limit + itemsPerPage}
              </span>{" "}
              of <span className="font-medium">{totalElements}</span> results
              Showing
            </p>
          ) : undefined}
        </div>
        <div>
          {totalElements > itemsPerPage ? (
            <>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <Button
                  onClick={handlePrevbtn}
                  disabled={currentPage === pages[0] ? true : false}
                  className="relative inline-flex items-center px-2 py-4 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </Button>
                {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}

                <Button
                  onClick={handleNextbtn}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                  className="relative inline-flex items-center px-2 py-4 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </Button>
              </nav>
            </>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
Pagination.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  numberOfElements: PropTypes.number,
  currentPageNumber: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  orderTable: PropTypes.string,
};
export default Pagination;
