import { Form, Select } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { authenticateToken } from '../../../utils/auth'
import { responseNotification } from '../../../utils/notify'
import { adminServices } from '../../../utils/services'
import { jcTypeArray } from '../../../utils'
import SubmitResetBtn from '../../common/SubmitBtn'
const { Option, OptGroup } = Select

const AddService = ({ visibleData, services = [], onCloseMethod }: any) => {
	const [error, setError] = useState('')
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [serviceData, setServiceData] = useState<any>(undefined)

	const onSubmit = async (data: any) => {
		setLoading(true)

		const readyData = data && {
			serviceName: data.serviceName,
			type: data.type,
		}

		if (serviceData) {
			await fetch(`${process.env.REACT_APP_AUTH_API}/service`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...readyData,
					id: serviceData?.id,
					deleted: false,
				}),
			})
				.then(res => res.json())
				.then(res => {
					setLoading(false)

					if (res.statusCode === 200) {
						responseNotification('Service Updated Successfully', 'success')
						form.resetFields()
						if (onCloseMethod) {
							onCloseMethod()
						}
					} else if (res.status === 500) {
						responseNotification('Internal server error', 'error')
					} else {
						responseNotification(res.message || 'something wrong', 'warning')
					}
				})
				.catch(err => {
					setLoading(false)
					responseNotification(`${'Internal server error'} ${err}`, 'error')
					console.error('err', err)
				})
		} else {
			await fetch(`${process.env.REACT_APP_AUTH_API}/service`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(readyData),
			})
				.then(res => res.json())
				.then(res => {
					setLoading(false)

					if (res.statusCode === 200) {
						responseNotification('Service Create Successfully', 'success')
						form.resetFields()
						if (onCloseMethod) {
							onCloseMethod()
						}
					} else if (res.status === 500) {
						responseNotification('Internal server error', 'error')
					} else {
						responseNotification(res.message || 'something wrong', 'warning')
					}
				})
				.catch(err => {
					setLoading(false)
					responseNotification(`${'Internal server error'} ${err}`, 'error')
					console.error('err', err)
				})
		}
	}

	useEffect(() => {
		if (visibleData) {
			setServiceData(visibleData)
		}
	}, [visibleData])

	useEffect(() => {
		if (serviceData) {
			form.resetFields(Object.keys(visibleData))
		}
	}, [serviceData, form, visibleData])

	const isExist = useCallback(
		(service: string | number) => {
			const hash = {} as any

			services?.map((item: { name: string | number }) => {
				if (item?.name) {
					hash[item?.name] = item
				}
				return true
			})
			if (hash[service]) {
				return true
			}
		},
		[services]
	)

	const filteredAdminServices = adminServices
		// eslint-disable-next-line array-callback-return
		?.map(service => {
			if (!service?.sub?.length) {
				return service
			} else {
				const subs = service?.sub?.filter(item => !isExist(item?.name))
				if (subs?.length) {
					return {
						...service,
						sub: subs,
					}
				}
			}
		})
		?.filter(item => item)

	return (
		<>
			<Form
				name='control-hooks'
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				onFinish={onSubmit}
				form={form} // like ref
				layout='vertical'
				initialValues={{
					...serviceData,
				}}
			>
				<Form.Item
					hasFeedback
					label='Name'
					rules={[
						{
							required: true,
							message: 'Name is Required!',
						},
					]}
					name='serviceName'
				>
					<Select placeholder='Select Service' size='large'>
						{filteredAdminServices?.flatMap((service, index) =>
							service?.sub ? (
								<OptGroup label={service?.name}>
									{Object.values(
										service?.sub.map((sub, i) => (
											<Option value={sub?.name} key={sub?.name}>
												{sub?.name}
											</Option>
										))
									)}
								</OptGroup>
							) : (
								<Option value={service?.name} key={index}>
									{service?.name}
								</Option>
							)
						)}
					</Select>
				</Form.Item>

				<Form.Item
					hasFeedback
					label='Type'
					rules={[
						{
							required: true,
							message: 'Type is required!',
						},
					]}
					name='type'
					initialValue='JC_PARCEL'
				>
					<Select placeholder='Group Type' style={{ minWidth: 300 }} size='large'>
						{Object.values(jcTypeArray)?.map((type, i) => (
							<Select.Option value={type} key={i}>
								{type?.replace('_', ' ')}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<SubmitResetBtn
					onClickReset={() => {
						form?.resetFields()
					}}
					disabled={loading || !!error}
					loading={loading}
				/>
			</Form>
		</>
	)
}

export default AddService
