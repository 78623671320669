import {
	LOGIN_LOADING,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	SIDEBAR_COLLAPSE,
	SET_DRIVER_INFO,
} from './authType'

const initialState = {
	name: '',
	email: '',
	role: '',
	exp: null,
	error: false,
	success: false,
	loading: false,
	type: null,
	sidebarCollapse: false,
	hubInfo: {
		id: '',
		name: '',
		image: '',
		type: '',
		deleted: '',
	},
	merchantInfo: {
		id: '',
		name: '',
		image: '',
		phone: '',
		email: '',
		deleted: '',
		merchantId: '',
	},
	shopInfo: {
		id: '',
		name: '',
		type: '',
		mobileNumber: '',
		rating: null,
		logo: '',
		banner: '',
		status: '',
		hubId: '',
		deleted: '',
	},
	driverInfo: {
		driverId: '',
		image: '',
		name: '',
		rating: null,
		balance: '',
		mobileNumber: '',
		status: '',
	},
	customerInfo: {
		image: '',
		fullName: '',
		rating: null,
		balance: '',
		mobileNumber: '',
	},
}

const authRedcer = (
	state = initialState,
	action = { type: '', payload: {} }
) => {
	switch (action.type) {
		case LOGIN_REQUEST: {
			state.loading = true
			return state
		}
		case LOGIN_SUCCESS: {
			state = { ...state, ...action.payload }
			state.loading = false
			return state
		}
		case LOGIN_LOADING: {
			return state
		}
		case SIDEBAR_COLLAPSE: {
			state = { ...state, ...action.payload }
			return state
		}
		default: {
			state.loading = false
			return state
		}

		case SET_DRIVER_INFO: {
			state = { ...state, ...action.payload }
			state.loading = false
			return state
		}
	}
}

export default authRedcer
