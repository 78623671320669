import {
	AutoComplete,
	Button,
	Drawer,
	Empty,
	Form,
	Input,
	Select,
	Spin,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useLocation } from 'react-router'
import axios from 'axios'
import moment from 'moment'
import { authenticateToken } from '../../utils/auth'
import SingleParcel from './Details'
import BreadCrumb from '../Layouts/Breadcrumb'
import { getPage, getParamValue, ParcelStatusArray } from '../../utils'
import Loader from '../common/Loader'
import { debounce } from 'lodash'
import { responseNotification } from '../../utils/notify'
import Pagination from '../common/Pagination'
import styles from '../../styles/tailwind/List.module.css'
const { Option } = Select

const RequestList = (): ReactElement => {
	const [form] = Form.useForm()
	const loc = useLocation()
	const page = getParamValue(loc.search, 'page')
	const [limit, setLimit] = useState(16)
	const [status, setStatus] = useState('')
	const [parcelId, setParcelId] = useState('')
	const [showSearch, setShowSearch] = useState(false)
	const [secondDrawer, setSecondDrawer] = useState(false)
	const [selectedParcel, setSelectedParcel] = useState<any>()
	const [driverId, setDriverId] = useState('')

	const [parcelData, setParcelData] = useState<any>({
		loading: false,
		data: null,
	})

	const [parcelOptions, setParcelOptions] = useState({
		loading: false,
		list: null,
	})

	const [driversOptions, setDriversOptions] = useState({
		loading: false,
		list: [],
	})

	const onClose = () => {
		setSelectedParcel(undefined)
		setSecondDrawer(false)
	}
	const onSecondDrawerClose = () => {
		setSecondDrawer(false)
	}

	const getParcels = useCallback(async () => {
		setParcelData({ loading: true, data: null })

		const encodedUrl = `${process.env.REACT_APP_PARCEL_API}`
		axios
			.get(
				`${encodedUrl}/admin/parcel` +
					`?page=${page || 0}` +
					(limit ? `&limit=${limit}` : ``) +
					(status ? `&status=${status}` : ``) +
					(parcelId ? `&parcelId=${parcelId}` : ``),
				{
					headers: {
						Authorization: `Bearer ${authenticateToken()}`,
					},
				}
			)
			.then(res => {
				setParcelData({ loading: false, data: res.data })
			})
			.catch(err => {
				setParcelData({ loading: false, data: [] })
				console.error('Parcel: Error', err)
			})
	}, [limit, page, parcelId, status])

	const getParcelOptions = async () => {
		setParcelOptions({ loading: true, list: null })

		const encodedUrl = `${process.env.REACT_APP_PARCEL_API}`
		axios
			.get(`${encodedUrl}/admin/parcel`, {
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
				},
			})
			.then(res => {
				setParcelOptions({
					loading: false,
					list: res.data?.parcels?.map((parcel: any) => ({
						label: parcel?.parcelId,
						value: parcel?.parcelId,
					})),
				})
			})
			.catch(err => {
				setParcelOptions({ loading: false, list: null })
				console.error('Parcel: Error', err)
			})
	}

	const getDriversOptions = useCallback(
		async (mobileNumber: string) => {
			setDriversOptions({ loading: true, list: [] })
			const encodedUri = `${process.env.REACT_APP_RIDER_API}`
			axios
				.get(
					`${encodedUri}/admin/driver?` +
						(mobileNumber
							? `&mobileNumberOrName=${mobileNumber?.replace('+88', '')}`
							: ``) +
						(status ? `&status=${status}` : ``) +
						`&page=${0}` +
						`&limit=${20}`,
					{
						headers: {
							Authorization: `Bearer ${authenticateToken()}`,
						},
					}
				)
				.then(res => {
					setDriversOptions({
						loading: false,
						list: res.data?.drivers?.map((driver: any) => ({
							label: `${driver?.mobileNumber.replace('+88', '')} - ${driver.name}`,
							value: driver?.id,
						})),
					})
				})
				.catch(err => {
					setDriversOptions({ loading: false, list: [] })
					console.error('Drivers: Error', err)
				})
		},
		[status]
	)

	//
	const onSubmit = async () => {
		// setConfirmLoading(id);

		await fetch(`${process.env.REACT_APP_PARCEL_API}/admin/parcel/assign-to-dm`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${authenticateToken()}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				parcelId: selectedParcel?.parcelId,
				deliveryManId: driverId,
			}),
		})
			.then(res => res.json())
			.then(res => {
				// setConfirmLoading(undefined);
				if (res.statusCode === 200) {
					responseNotification('Driver Assigned Updated Successfully', 'success')
					onClose()
					onSecondDrawerClose()
				} else if (res.status === 500) {
					responseNotification('Internal server error', 'error')
				} else {
					responseNotification(res.message || 'something wrong', 'warning')
				}
			})
			.catch(err => {
				responseNotification(`${'Internal server error'} ${err}`, 'error')
				console.error('err', err)
			})
	}
	//

	const fetchRef = useRef(0)
	const handleSearch = useMemo(() => {
		const loadOptions = (value: string, field: string) => {
			fetchRef.current += 1
			const fetchId = fetchRef.current

			if (fetchId !== fetchRef.current) {
				return
			}
			if (value) {
				if (field === 'driver') getDriversOptions(value)
			}
		}

		return debounce(loadOptions, 800)
	}, [getDriversOptions])

	useEffect(() => {
		getParcels()
	}, [getParcels])

	useEffect(() => {
		if (showSearch) {
			getParcelOptions()
			// getDriversOptions('')
		}
	}, [showSearch])

	useEffect(() => {
		if (secondDrawer) {
			getDriversOptions('')
			setDriverId(``)
			form.resetFields()
		}
	}, [secondDrawer, form])

	const reseAllFieldData = () => {
		form?.resetFields()
		setStatus('')
		setParcelId('')
	}

	return (
		<>
			<BreadCrumb
				title='Parcel List'
				subTitle={`${parcelData?.data?.totalElements} Parcel(s)`}
				extra={[
					<Button
						type='dashed'
						shape='circle'
						onClick={() => setShowSearch(!showSearch)}
						key={1}
					>
						<SearchOutlined />
					</Button>,
				]}
			/>
			{showSearch && (
				<div className={styles.searchBox}>
					<Form layout='inline' form={form} className={styles.formInline}>
						<Form.Item name='parcel'>
							<AutoComplete
								dropdownClassName='certain-category-search-dropdown'
								dropdownMatchSelectWidth={250}
								style={{ width: 250 }}
								onSelect={(val: any) => setParcelId(val.toString())}
								options={parcelOptions?.list || undefined}
								defaultActiveFirstOption={false}
								notFoundContent={parcelOptions?.loading ? <Spin size='small' /> : null}
							>
								<Input.Search
									placeholder='Search by ID'
									onSearch={val => setParcelId(val)}
									enterButton
									loading={parcelOptions.loading}
									size='large'
								/>
							</AutoComplete>
						</Form.Item>

						<Form.Item name='status'>
							<Select
								size='large'
								placeholder='Select Status'
								onChange={val => setStatus(val)}
							>
								<Option value=''>All</Option>
								{Object.values(ParcelStatusArray).map((status, i) => (
									<Option key={i} value={status}>
										{status?.split('_').join(' ')}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Form>{' '}
					<Button type='primary' danger size='large' onClick={reseAllFieldData}>
						Reset
					</Button>
				</div>
			)}
			<div className={styles.contentWrapper}>
				<div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
						<div className={showSearch ? `content-body-withSearch` : `content-body`}>
							{parcelData?.loading ? (
								<Loader />
							) : (
								<table className={styles.mainTable}>
									<thead className='bg-white border-b'>
										<tr>
											<th scope='col'>Parcel ID</th>
											<th scope='col'>Date</th>
											<th scope='col'>Amount</th>
											<th scope='col'>Payment Method</th>
											<th scope='col'>Payment Method</th>
											<th scope='col'>Status</th>
										</tr>
									</thead>

									<tbody>
										{parcelData?.data?.parcels?.length ? (
											parcelData?.data?.parcels?.map((parcel: any, index: any) => (
												<tr
													className='border-t hover:bg-gray-100'
													key={index}
													onClick={() => setSelectedParcel(parcel)}
												>
													<td>{parcel?.parcelId}</td>
													<td>{moment(parcel?.createdAt).format('lll')}</td>
													<td>&#2547;{parseInt(parcel?.amount)}</td>
													<td>{parcel?.paymentMethod}</td>
													<td>{parcel?.paymentStatus}</td>
													<td>
														<span className='border p-1 px-5 rounded-2xl bg-white'>
															{parcel?.status}
														</span>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td>
													<Empty />
												</td>
											</tr>
										)}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>

				<Pagination
					{...parcelData?.data}
					limit={limit}
					page={getPage(loc.search)}
				/>
			</div>

			<Drawer
				title='Parcel Details'
				placement='right'
				onClose={onClose}
				visible={selectedParcel}
				width={500}
				extra={
					<Button type='dashed' onClick={() => setSecondDrawer(true)}>
						Assign DM
					</Button>
				}
			>
				<SingleParcel ParcelDetails={selectedParcel} />

				<Drawer
					title='Assign DM'
					placement='right'
					onClose={onSecondDrawerClose}
					visible={secondDrawer}
					width={400}
				>
					<Form layout='vertical' onFinish={onSubmit}>
						<Form.Item label='Select DM'>
							<Select
								allowClear
								showSearch
								placeholder='Filter by Merchant'
								optionFilterProp='children'
								onChange={val => setDriverId(val)}
								onSearch={e => handleSearch(e, 'driver')}
								filterOption={(input, option) => {
									console.log(input, option)
									return true
								}}
								options={driversOptions?.list}
								size='large'
							></Select>
						</Form.Item>
						<Button htmlType='submit'>Assign</Button>
					</Form>
				</Drawer>
			</Drawer>
		</>
	)
}

export default RequestList
