import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import promiseMiddleware from "redux-promise";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

export const store: any = createStore(
  rootReducer,
  compose(applyMiddleware(promiseMiddleware, thunk))
);

export const persistor = persistStore(store);
