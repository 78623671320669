import { Avatar } from "antd";
import { ReactElement } from "react";

const SingleParcel = ({ TypeDetails }: { TypeDetails: any }): ReactElement => {
  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>
                <Avatar
                  size={40}
                  src={TypeDetails?.logo}
                  style={{
                    background: TypeDetails?.backgroundColor,
                    padding: 10,
                    marginRight: 5,
                  }}
                />{" "}
                {TypeDetails?.name}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Description</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{TypeDetails?.description}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleParcel;
