import DeliveryHeroList from '../../components/Delivery/DeliveryHero'
import MainLayout from '../../components/Layouts'

function DeliveryHeroPage() {
	return (
		<MainLayout>
			<DeliveryHeroList />
		</MainLayout>
	)
}

export default DeliveryHeroPage
