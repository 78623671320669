import {
	faAlignLeft,
	faBiking,
	faCopy,
	faLaptop,
	faList,
	faTruck,
	faUser,
	faUsers,
	faUserShield,
} from '@fortawesome/free-solid-svg-icons'

import UserManagementPage from '../pages/administration'
import { ServiceType } from '../type/service'
import ServiceGroupPage from '../pages/administration/groups'
import UserServicePage from '../pages/administration/services'
import RequestPage from '../pages/requests'
import CompanyRequestPage from '../pages/Company'
import ParcelTypePage from '../pages/parcel-type'
import DeliveryHeroPage from '../pages/delivery'
import DashboardPage from '../pages'
import DriverDetailsPage from '../pages/delivery/details'

export const isAllowedService = (name: string) => {
	const services = localStorage.getItem('services')

	if (services) {
		const list: ServiceType[] = JSON.parse(services)
		const data = list.find(item => {
			console.log('Itemsss:', item.serviceName, 1, name)
			return item.serviceName === name
		})
		if (data) {
			return true
		}
	}
	return false
}

export const allowedActions = (name: string) => {
	const services = localStorage.getItem('services')

	if (services) {
		const list: ServiceType[] = JSON.parse(services)
		const data = list.filter(item => item.serviceName === name)
		if (data.length > 0) {
			data.map(d => d.action)
		}
	}
	return []
}

export const isEditableService = (name: string) => {
	const services = localStorage.getItem('services')

	if (services) {
		const list: ServiceType[] = JSON.parse(services)
		const data = list.find(
			item => item.serviceName === name && item.action === 'all'
		)

		if (data) {
			return true
		}
	}
	return false
}

export const getAllServices = () => {
	const services = localStorage.getItem('services')
	if (services) {
		return JSON.parse(services)
	}
	return []
}

type AdminService = {
	name: string
	path?: string
	icon?: any
	element?: any
	sub?: AdminService[]
}

export const adminServices: AdminService[] = [
	{
		name: 'Dashboard',
		path: '/',
		icon: faLaptop,
		element: DashboardPage,
	},
	{
		name: 'Parcel Requests',
		path: '/requests',
		icon: faCopy,
		element: RequestPage,
	},
	{
		name: 'E-Cab Parcel',
		path: '/company-requests',
		icon: faCopy,
		element: CompanyRequestPage,
	},
	{
		name: 'Parcel Type',
		path: '/parcel-type',
		icon: faAlignLeft,
		element: ParcelTypePage,
	},
	{
		name: 'Delivery',
		path: '/delivery',
		icon: faTruck,
		sub: [
			{
				name: 'Drivers',
				path: '/drivers',
				icon: faBiking,
				element: DeliveryHeroPage,
			},
			{
				name: 'Drivers Details',
				path: '/drivers/:id/details',
				element: DriverDetailsPage,
			},
		],
	},
	{
		name: 'Administration',
		path: '/administration',
		icon: faUserShield,
		sub: [
			{
				name: 'User Management',
				icon: faUser,
				path: '/admins',
				element: UserManagementPage,
			},
			{
				name: 'User Groups',
				icon: faUsers,
				path: '/groups',
				element: ServiceGroupPage,
			},
			{
				name: 'User Services',
				icon: faList,
				path: '/services',
				element: UserServicePage,
			},
		],
	},
]

export const getAllServicesList = () => {
	return adminServices?.flatMap(service => {
		if (service.sub && service.sub.length) {
			return service.sub.map(sub => `${service.name} ${sub.name}`)
		} else {
			return service.name
		}
	})
}

console.log('All Services List', getAllServicesList())
