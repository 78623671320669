import {
	AutoComplete,
	Avatar,
	Button,
	Drawer,
	Empty,
	Form,
	Input,
	Select,
	Spin,
	Switch,
} from 'antd'
import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	SearchOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { debounce } from 'lodash'
import BreadCrumb from '../Layouts/Breadcrumb'
import AddBtn from '../../components/common/AddBtn'
import { getPage, getParamValue } from '../../utils/'
import Loader from '../../components/common/Loader'
import { useLocation } from 'react-router'
import { authenticateToken } from '../../utils/auth'
import Pagination from '../common/Pagination'
import styles from '../../styles/tailwind/List.module.css'
import SingleUser from './Details'
import AddUser from './AddUser'
const { Option } = Select

const UserManagementList = (): ReactElement => {
	const [form] = Form.useForm()
	const loc = useLocation()
	const page = getParamValue(loc.search, 'page')
	const [limit, setLimit] = useState(16)
	const [status, setStatus] = useState('')
	const [visible, setVisible] = useState<any>(undefined)
	const [showSearch, setShowSearch] = useState(false)
	const [selectedUser, setSelectedUser] = useState(undefined)
	const [selectedUserForEdit, setSelectedUserForEdit] = useState(undefined)
	const [mobileNumber, setMobileNumber] = useState('')

	const [userData, setUserData] = useState<any>({
		loading: false,
		data: null,
	})

	const [userOptions, setUserOptions] = useState({
		loading: false,
		list: null,
	})

	const getUsers = useCallback(async () => {
		setUserData({ loading: true, data: null })

		const encodedUrl = `${process.env.REACT_APP_AUTH_API}`
		axios
			.get(
				`${encodedUrl}/admin/all/V2` +
					`?page=${page || 0}` +
					(limit ? `&limit=${limit}` : ``) +
					(status ? `&status=${status}` : ``) +
					(mobileNumber ? `&nameOrEmail=${mobileNumber?.replace('+88', '')}` : ``),
				{
					headers: {
						Authorization: `Bearer ${authenticateToken()}`,
					},
				}
			)
			.then(res => {
				setUserData({ loading: false, data: res.data })
			})
			.catch(err => {
				setUserData({ loading: false, data: [] })
				console.error('Admins: Error', err)
			})
	}, [limit, page, mobileNumber, status])

	const getUserOptions = useCallback(
		async (mobileNumber: string) => {
			setUserOptions({ loading: true, list: null })
			const encodedUri = `${process.env.REACT_APP_AUTH_API}`
			axios
				.get(
					`${encodedUri}/admin/all/V2?` +
						`page=${0}` +
						`&limit=${20}` +
						(mobileNumber
							? `&mobileNumberOrName=${mobileNumber?.replace('+88', '')}`
							: ``) +
						(status ? `&status=${status}` : ``),
					{
						headers: {
							Authorization: `Bearer ${authenticateToken()}`,
						},
					}
				)
				.then(res => {
					setUserOptions({
						loading: false,
						list: res.data?.admins?.map((admin: any) => ({
							label: `${admin.mobileNumber.replace('+88', '')} - ${admin.name}`,
							value: admin.mobileNumber.replace('+88', ''),
						})),
					})
				})
				.catch(err => {
					setUserOptions({ loading: false, list: null })
					console.error('Admins: Error', err)
				})
		},
		[status]
	)

	const fetchRef = useRef<any>(null)
	const getuserOptionsDebounce = useMemo(() => {
		const loadOptions = (value: string) => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setUserOptions({ loading: false, list: null })
			if (fetchId !== fetchRef.current) {
				return
			}
			getUserOptions(value)
		}

		return debounce(loadOptions, 800)
	}, [getUserOptions])

	useEffect(() => {
		getUsers()
	}, [getUsers])

	useEffect(() => {
		if (showSearch) {
			getUserOptions('')
			form.resetFields()
		}
	}, [showSearch])

	const onClose = () => {
		setSelectedUser(undefined)
		setSelectedUserForEdit(undefined)
		setVisible(undefined)
		getUsers()
	}

	const reseAllFieldData = () => {
		form.resetFields()
		setStatus('')
		setMobileNumber('')
	}

	return (
		<>
			<BreadCrumb
				title='User List'
				subTitle={`${userData?.data?.totalElements} User(s)`}
				extra={[
					<Button
						type='dashed'
						shape='circle'
						onClick={() => setShowSearch(!showSearch)}
						key={1}
					>
						<SearchOutlined />
					</Button>,
					<AddBtn onClick={() => setVisible(true)} key={2} />,
				]}
			/>
			{showSearch && (
				<div className={styles.searchBox}>
					<Form form={form} layout='inline' className={styles.formInline}>
						<Form.Item name='nameOrEmail'>
							<AutoComplete
								dropdownClassName='certain-category-search-dropdown'
								dropdownMatchSelectWidth={250}
								style={{ width: 250 }}
								onSearch={getuserOptionsDebounce}
								onSelect={(val: any) => setMobileNumber(val.toString())}
								options={userOptions?.list || undefined}
								defaultActiveFirstOption={false}
								notFoundContent={userOptions?.loading ? <Spin size='small' /> : null}
							>
								<Input.Search
									placeholder='Search by name or email'
									onSearch={val => setMobileNumber(val)}
									enterButton
									loading={userOptions.loading}
									size='large'
								/>
							</AutoComplete>
						</Form.Item>

						<Form.Item name='status'>
							<Select
								size='large'
								placeholder='Filter By Status'
								onChange={val => setStatus(val)}
							>
								<Option value=''>All Users</Option>
								<Option value='true'>Active</Option>
								<Option value='false'>Inactive</Option>
							</Select>
						</Form.Item>
					</Form>
					<Button type='primary' danger size='large' onClick={reseAllFieldData}>
						Reset
					</Button>
				</div>
			)}

			<div className={styles.contentWrapper}>
				<div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
						<div className={showSearch ? `content-body-withSearch` : `content-body`}>
							{userData?.loading ? (
								<Loader />
							) : (
								<table className={styles.mainTable}>
									<thead className='bg-white border-b'>
										<tr>
											<th scope='col'>Full Name</th>
											<th scope='col'>Email Address</th>
											<th scope='col'>Status</th>
											<th scope='col'>Action</th>
										</tr>
									</thead>

									<tbody>
										{userData?.data?.admins?.length ? (
											userData?.data?.admins?.map((admin: any, index: any) => (
												<tr
													className='border-t hover:bg-gray-100'
													key={index}
													onClick={() => setSelectedUser(admin?.email)}
												>
													<td>
														<Avatar size={40} src={admin?.profilePictureUrl} />{' '}
														{admin?.fullName}
													</td>
													<td>{admin?.email}</td>
													<td>
														<Switch
															checkedChildren={<CheckOutlined />}
															unCheckedChildren={<CloseOutlined />}
															defaultChecked={admin?.giftDelivered}
															onChange={(val, e) => {
																e.preventDefault()
																e.stopPropagation()
															}}
														/>
													</td>
													<td>
														<Button
															shape='circle'
															className='bg-white'
															onClick={() => setSelectedUserForEdit(admin)}
														>
															<EditOutlined />
														</Button>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td>
													<Empty />
												</td>
											</tr>
										)}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>

				<Pagination {...userData?.data} limit={limit} page={getPage(loc.search)} />
			</div>

			<Drawer
				title={
					visible ? 'Add User' : selectedUserForEdit ? 'Edit User' : 'User Details'
				}
				placement='right'
				onClose={onClose}
				visible={visible || selectedUser || selectedUserForEdit}
				width={450}
			>
				{visible || selectedUserForEdit ? (
					<AddUser onCloseMethod={onClose} UserDetails={selectedUserForEdit} />
				) : (
					<SingleUser UserDetails={selectedUser} onCloseMethod={onClose} />
				)}
			</Drawer>
		</>
	)
}

export default UserManagementList
