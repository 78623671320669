import { Divider, Steps } from "antd";
import moment from "moment";
import { ReactElement } from "react";
const { Step } = Steps;

const SingleParcel = ({
  ParcelDetails,
}: {
  ParcelDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <div className="">
          <Steps progressDot direction="vertical" size="small" current={1}>
            <Step
              title="Pickup Address"
              description={ParcelDetails?.pickupAddress}
            />
            <Step
              title="Destination Address"
              description={ParcelDetails?.destinationAddress}
            />
          </Steps>
        </div>
        <Divider />
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Parcel ID</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{ParcelDetails?.parcelId}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Date</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{moment(ParcelDetails?.createdAt).format("lll")}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Amount</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>&#2547;{ParcelDetails?.amount}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Payment Method</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{ParcelDetails?.paymentMethod}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Payment Status</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{ParcelDetails?.paymentStatus}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Status</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{ParcelDetails?.status}</span>
            </div>
          </div>
        </div>

        {ParcelDetails?.note && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Note</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.note}</span>
              </div>
            </div>
          </div>
        )}

        <div className="mt-10">
          <Divider orientation="left">Receiver Info</Divider>
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Receiver Contact</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.receiverMobileNumber}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Receiver Name</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.receiverName}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <Divider orientation="left">Customer Info</Divider>
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Customer Name</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.customer?.name}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Mobile Number</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>
                  {ParcelDetails?.customer?.mobileNumber?.replace("+88", "")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleParcel;
