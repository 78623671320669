import { ReactElement } from "react";
import styled from "styled-components";
import Loader1 from "../../images/loader.svg";

const LoaderWrapper = styled.div`
  height: 200px;
  width: 200px;
  margin: auto;
  position: relative;
`;
const LoaderContent = styled.div`
  position: absolute;
  height: 100px;
  width: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(48, 45, 38, 0.3);
  // padding: 10px;
  img {
    border-radius: 50%;
  }
`;
const LoaderImg = styled.img`
  height: 100%;
  width: 100%;
`;
const Loader = (): ReactElement => {
  return (
    <LoaderWrapper className="loader-wrapper">
      <LoaderContent className="loader-content">
        <LoaderImg src={Loader1} className="loader-img" alt="Loading..." />
      </LoaderContent>
    </LoaderWrapper>
  );
};

export default Loader;
