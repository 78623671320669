import Cookies from "js-cookie";
import { redirect } from "./index";

export const logout = async (redirect_url: string) => {
  try {
    await Cookies.remove("authToken");
    redirect(redirect_url);
  } catch (e) {
    console.error("Error: logout", e);
  }
};

// Token related
export const authenticateToken = () => {
  const token = Cookies.get("authToken");
  if (token) {
    return token;
  }
  return false;
};

export const setAuthToken = (token: string) => {
  try {
    Cookies.set("authToken", token);
    return true;
  } catch (e) {
    console.error("Error: Token set", e);
    return false;
  }
};

export const removeAuthToken = () => {
  try {
    Cookies.remove("authToken");
    return true;
  } catch (e) {
    console.error("Error: Token remove", e);
    return false;
  }
};
