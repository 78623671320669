import Cookies from "js-cookie";
import { SetStateAction, useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = Cookies.get("authToken");
    const userToken = tokenString;
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: any) => {
    sessionStorage.setItem("token", JSON.stringify(userToken));
    Cookies.set("token", userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
