import { Form, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import ImgCrop from "antd-img-crop";

interface FileType {
  originFileObj: any;
  uid: string;
  name: string;
  status: string;
  url: string;
  crop: number;
}

const ImageUploader = ({
  imgUrl,
  setGeneratedImgUrl,
  startUpload,
  setStartUpload,
  crop,
}: any) => {
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setFileList(
      imgUrl
        ? ([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: imgUrl,
            },
          ] as FileType[])
        : []
    );
  }, [imgUrl]);

  const [form] = Form.useForm();

  const onChange = ({ fileList: newFileList }: any) => {
    setStartUpload?.("Initiated");
    setFileList(newFileList);
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("frontImage", fileList?.[0]?.originFileObj);
    formData.append("documentType", "static_image");

    await fetch(`${process.env.REACT_APP_RIDER_API}/admin/document/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authenticateToken()}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setStartUpload?.("Uploaded");
        setGeneratedImgUrl?.(res.url || imgUrl);
        setFileList([]);
        form.resetFields();
      })
      .catch((err) => {
        setStartUpload?.(undefined);
        console.error("err", err);
      });
  };

  useEffect(() => {
    if (startUpload === "Uploading") {
      uploadImage();
    }
  }, [startUpload]);

  return (
    <React.Fragment>
      {crop ? (
        <ImgCrop aspect={crop}>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            onChange={onChange}
            onPreview={onPreview}
            fileList={fileList as any}
          >
            {fileList.length < 1 && "+ Upload"}
          </Upload>
        </ImgCrop>
      ) : (
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          onChange={onChange}
          onPreview={onPreview}
          fileList={fileList as any}
        >
          {fileList.length < 1 && "+ Upload"}
        </Upload>
      )}

      <Modal
        visible={visible}
        title="Image"
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={imgUrl} />
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(ImageUploader);
