import { Divider, Form, Input } from 'antd'
import { useEffect, useState } from 'react'
import { authenticateToken } from '../../utils/auth'
import { responseNotification } from '../../utils/notify'
import ImageUploader from '../common/ImageUploader'
import SubmitResetBtn from '../common/SubmitBtn'

const AddParcel = ({ visibleData, onCloseMethod }: any) => {
	const [startUpload, setStartUpload] = useState<
		'Initiated' | 'Uploading' | 'Uploaded'
	>()
	const [generatedImgUrl, setGeneratedImgUrl] = useState<string>()
	const [error, setError] = useState('')
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [parcelTypeData, setParcelTypeData] = useState<any>(undefined)

	useEffect(() => {
		if (startUpload === 'Uploaded') {
			form.submit()
		}
	}, [form, startUpload])

	const onSubmit = async (data: any) => {
		if (generatedImgUrl) {
			setLoading(true)

			const readyData = data && {
				name: data.name,
				description: data.description,
				backgroundColor: data.backgroundColor,
				logo: generatedImgUrl ? generatedImgUrl : data?.logo,
			}

			if (parcelTypeData) {
				await fetch(`${process.env.REACT_APP_PARCEL_API}/parcel-type`, {
					method: 'PUT',
					headers: {
						Authorization: `Bearer ${authenticateToken()}`,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						...readyData,
						id: parcelTypeData?.id,
						deleted: false,
					}),
				})
					.then(res => res.json())
					.then(res => {
						setLoading(false)

						if (res.statusCode === 200) {
							responseNotification('Type Updated Successfully', 'success')
							form.resetFields()
							if (onCloseMethod) {
								onCloseMethod()
							}
						} else if (res.status === 500) {
							responseNotification('Internal server error', 'error')
						} else {
							responseNotification(res.message || 'something wrong', 'warning')
						}
					})
					.catch(err => {
						setLoading(false)
						responseNotification(`${'Internal server error'} ${err}`, 'error')
						console.error('err', err)
					})
			} else {
				await fetch(`${process.env.REACT_APP_PARCEL_API}/parcel-type`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${authenticateToken()}`,
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(readyData),
				})
					.then(res => res.json())
					.then(res => {
						setLoading(false)

						if (res.statusCode === 200) {
							responseNotification('Parcel Create Successfully', 'success')
							form.resetFields()
							if (onCloseMethod) {
								onCloseMethod()
							}
						} else if (res.status === 500) {
							responseNotification('Internal server error', 'error')
						} else {
							responseNotification(res.message || 'something wrong', 'warning')
						}
					})
					.catch(err => {
						setLoading(false)
						responseNotification(`${'Internal server error'} ${err}`, 'error')
						console.error('err', err)
					})
			}
		} else {
			responseNotification(`Image required`, 'error')
		}
	}

	useEffect(() => {
		if (visibleData) {
			setParcelTypeData(visibleData)
			setGeneratedImgUrl(visibleData?.logo)
		}
	}, [visibleData])

	useEffect(() => {
		if (parcelTypeData) {
			form.resetFields(Object.keys(visibleData))
			setGeneratedImgUrl(visibleData?.logo)
		}
	}, [parcelTypeData, form, visibleData])

	return (
		<>
			<Form
				name='control-hooks'
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				className='ant-form ant-form-vertical'
				onFinish={onSubmit}
				form={form} // like ref
				layout='vertical'
				initialValues={{
					...parcelTypeData,
				}}
			>
				<Form.Item
					hasFeedback
					label='Name'
					rules={[
						{
							required: true,
							message: 'Name is Required!',
						},
					]}
					name='name'
				>
					<Input id='name' type='text' placeholder='Enter Name' size='large' />
				</Form.Item>

				<Form.Item
					hasFeedback
					label='Description'
					rules={[
						{
							required: true,
							message: 'Description is Required!',
						},
					]}
					name='description'
				>
					<Input.TextArea
						rows={4}
						id='description'
						placeholder='Enter description'
						size='large'
					/>
				</Form.Item>

				<Form.Item
					hasFeedback
					label='Background Color'
					rules={[
						{
							required: true,
							message: 'Background Color is Required!',
						},
					]}
					name='backgroundColor'
				>
					<Input
						id='backgroundColor'
						type='color'
						placeholder='Pick Background Color'
						className='p-0'
						size='large'
					/>
				</Form.Item>

				<Divider orientation='left'>Logo</Divider>
				<ImageUploader
					imgUrl={generatedImgUrl || ''}
					startUpload={startUpload}
					setStartUpload={(val: any) => setStartUpload(val)}
					setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
					crop={1}
				/>

				<SubmitResetBtn
					onClickSubmit={e => {
						e.preventDefault()
						setStartUpload('Uploading')
					}}
					onClickReset={() => {
						form?.resetFields()
						setGeneratedImgUrl(undefined)
						setStartUpload(undefined)
					}}
					disabled={loading || !!error}
					loading={loading}
				/>
			</Form>
		</>
	)
}

export default AddParcel
