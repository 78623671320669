import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { authenticateToken } from "../../utils/auth";
import SingleParcel from "./Details";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import { responseNotification } from "../../utils/notify";
import Pagination from "../../components/common/Pagination";
import styles from "../../styles/tailwind/List.module.css";

const CompanyRequestList = (): ReactElement => {
  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [nameOrMobileNumber, setNameOrMobileNumber] = useState("");
  const [parcelCompany, setParcelCompany] = useState("");
  const [deliveredId, setDeliveredId] = useState<string>();
  const [showSearch, setShowSearch] = useState(false);
  const [secondDrawer, setSecondDrawer] = useState(false);
  const [selectedParcel, setSelectedParcel] = useState<any>();
  const [driverId, setDriverId] = useState("");

  const [parcelData, setParcelData] = useState<any>({
    loading: false,
    data: null,
  });

  const [parcelOptions, setParcelOptions] = useState({
    loading: false,
    list: null,
  });

  const [parcelOptionsByCompany, setParcelOptionsByCompany] = useState({
    loading: false,
    list: null,
  });

  const [driversOptions, setDriversOptions] = useState({
    loading: false,
    list: [],
  });

  const getParcels = useCallback(async () => {
    setParcelData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/ecab` +
          `?page=${page || 0}` +
          (nameOrMobileNumber
            ? `&nameOrMobileNumber=${nameOrMobileNumber?.replace("+88", "")}`
            : ``) +
          (limit ? `&limit=${limit}` : ``) +
          (status ? `&status=${status}` : ``) +
          (parcelCompany ? `&company=${parcelCompany}` : ``),
        {
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
          },
        }
      )
      .then((res) => {
        setParcelData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setParcelData({ loading: false, data: null });
        console.error("Parcel: Error", err);
      });
  }, [limit, nameOrMobileNumber, page, parcelCompany, status]);

  const getParcelOptionsByCompany = useCallback(
    async (val: string) => {
      setParcelOptionsByCompany({ loading: true, list: null });

      const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUrl}/admin/ecab` +
            `?page=${page || 0}` +
            (val ? `&company=${val}` : ``) +
            (limit ? `&limit=${limit}` : ``) +
            (status ? `&status=${status}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setParcelOptionsByCompany({
            loading: false,
            list: res.data?.ecabs?.map((parcel: any) => ({
              label: parcel?.companyName,
              value: parcel?.companyName,
            })),
          });
        })
        .catch((err) => {
          setParcelOptionsByCompany({ loading: false, list: null });
          console.error("Parcel: Error", err);
        });
    },
    [limit, page, status]
  );

  const getParcelOptions = useCallback(
    async (val: any) => {
      setParcelOptions({ loading: true, list: null });

      const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUrl}/admin/ecab` +
            `?page=${page || 0}` +
            (val ? `&nameOrMobileNumber=${val?.replace("88", "")}` : ``) +
            (limit ? `&limit=${limit}` : ``) +
            (status ? `&status=${status}` : ``),
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setParcelOptions({
            loading: false,
            list: res.data?.ecabs?.map((parcel: any) => ({
              label: parcel?.nameOfOwner,
              value: parcel?.mobileNumber?.replace("+88", ""),
            })),
          });
        })
        .catch((err) => {
          setParcelOptions({ loading: false, list: null });
          console.error("Parcel: Error", err);
        });
    },
    [limit, page, status]
  );

  const getDriversOptions = useCallback(
    async (mobileNumber: string) => {
      setDriversOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/driver?` +
            (mobileNumber
              ? `&nameOrMobileNumber=${mobileNumber?.replace("+88", "")}`
              : ``) +
            (status ? `&status=${status}` : ``) +
            `&page=${0}` +
            `&limit=${20}`,
          {
            headers: {
              Authorization: `Bearer ${authenticateToken()}`,
            },
          }
        )
        .then((res) => {
          setDriversOptions({
            loading: false,
            list: res.data?.drivers?.map((driver: any) => ({
              label: `${driver?.mobileNumber.replace("+88", "")} - ${
                driver.name
              }`,
              value: driver?.id,
            })),
          });
        })
        .catch((err) => {
          setDriversOptions({ loading: false, list: [] });
          console.error("Drivers: Error", err);
        });
    },
    [status]
  );

  const onStatusChange = async () => {
    if (deliveredId) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/admin/ecab/${deliveredId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            getParcels();
            setParcelCompany("");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "driver") getDriversOptions(value);
        else if (field === "company") getParcelOptionsByCompany(value);
        else if (field === "parcel") getParcelOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getDriversOptions, getParcelOptions, getParcelOptionsByCompany]);

  useEffect(() => {
    getParcels();
  }, [getParcels]);

  useEffect(() => {
    if (showSearch) {
      getParcelOptions("");
      getParcelOptionsByCompany("");
      getDriversOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    if (secondDrawer) {
      getDriversOptions("");
      setDriverId(``);
      form.resetFields();
    }
  }, [secondDrawer]);

  const onClose = () => {
    setSelectedParcel(undefined);
    setSecondDrawer(false);
  };
  const onSecondDrawerClose = () => {
    setSecondDrawer(false);
  };

  const reseAllFieldData = () => {
    form.resetFields();
    setStatus("");
    setParcelCompany("");
    setNameOrMobileNumber("");
  };

  const confirmSettlement = () => {
    Modal.confirm({
      title: "Delivered The Parcel?",
      icon: <ExclamationCircleOutlined />,
      content: `Do you confirm that this Parcel is Delivered?`,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: onStatusChange,
    });
  };

  useEffect(() => {
    if (deliveredId) {
      confirmSettlement();
    }
  }, [deliveredId]);

  return (
    <>
      <BreadCrumb
        title="e-Cab Parcel List"
        subTitle={`${parcelData?.data?.totalElements} Parcel(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
        ]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="company">
              <AutoComplete
                dropdownMatchSelectWidth={250}
                onSearch={getParcelOptionsByCompany}
                onSelect={(val: any) => setParcelCompany(val.toString())}
                options={parcelOptionsByCompany?.list || undefined}
                defaultActiveFirstOption={false}
                notFoundContent={
                  parcelOptionsByCompany?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Company"
                  onSearch={(val) => setParcelCompany(val)}
                  enterButton
                  loading={parcelOptionsByCompany.loading}
                  size="large"
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item name="user">
              <AutoComplete
                dropdownMatchSelectWidth={250}
                style={{ width: 250 }}
                onSearch={(e) => handleSearch(e, "parcel")}
                onSelect={(val: any) => setNameOrMobileNumber(val.toString())}
                options={parcelOptions?.list || undefined}
                defaultActiveFirstOption={false}
                notFoundContent={
                  parcelOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by User"
                  onSearch={(val) => setNameOrMobileNumber(val)}
                  enterButton
                  loading={parcelOptions.loading}
                  size="large"
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles?.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {parcelData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name Of Owner</th>
                      <th scope="col">Company Name</th>
                      <th scope="col">Voter Number</th>
                      <th scope="col">Serial Number</th>
                      <th scope="col">Mobile Number</th>

                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {parcelData?.data?.ecabs?.length ? (
                      parcelData?.data?.ecabs?.map(
                        (parcel: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedParcel(parcel)}
                          >
                            <td>
                              <Avatar
                                src={parcel?.imageUrl}
                                size={45}
                                className="mr-2"
                              />
                              <span className="font-medium">
                                {parcel?.nameOfOwner}
                              </span>
                            </td>
                            <td>{parcel?.companyName}</td>
                            <td>{parcel?.voterNumber}</td>
                            <td>{parcel?.serialNumber}</td>

                            <td>
                              {parcel?.mobileNumber?.split("88" || "+88")}
                            </td>
                            <td>
                              <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={parcel?.giftDelivered}
                                onChange={(val, e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setDeliveredId(parcel?.id);
                                }}
                                disabled={parcel?.giftDelivered}
                              />
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <Pagination
          {...parcelData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title="Parcel Details"
        placement="right"
        onClose={onClose}
        visible={selectedParcel}
        width={600}
        // extra={
        //   <Button type="dashed" onClick={() => setSecondDrawer(true)}>
        //     Assign DM
        //   </Button>
        // }
      >
        <SingleParcel ParcelDetails={selectedParcel} />

        <Drawer
          title="Assign DM"
          placement="right"
          onClose={onSecondDrawerClose}
          visible={secondDrawer}
          width={400}
        >
          <Form
            layout="vertical"
            // onFinish={onSubmit}
          >
            <Form.Item label="Select DM">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Merchant"
                optionFilterProp="children"
                onChange={(val) => setDriverId(val)}
                onSearch={(e) => handleSearch(e, "driver")}
                filterOption={(input, option) => {
                  console.log(input, option);
                  return true;
                }}
                options={driversOptions?.list}
                size="large"
              ></Select>
            </Form.Item>
            <Button htmlType="submit">Assign</Button>
          </Form>
        </Drawer>
      </Drawer>
    </>
  );
};

export default CompanyRequestList;
