import { Avatar, Divider, Steps } from "antd";
import moment from "moment";
import { ReactElement } from "react";
const { Step } = Steps;

const SingleParcel = ({
  ParcelDetails,
}: {
  ParcelDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        
        {/* <div className="">
          <Steps progressDot direction="vertical" size="small" current={1}>
            <Step
              title="Pickup Address"
              description={ParcelDetails?.pickupAddress}
            />
            <Step
              title="Destination Address"
              description={ParcelDetails?.destinationAddress}
            />
          </Steps>
        </div>
        <Divider /> */}

        {ParcelDetails?.nameOfOwner && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Name Of Owner</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <Avatar
                  src={ParcelDetails?.imageUrl}
                  size={50}
                  className="mr-2"
                />
                <span>{ParcelDetails?.nameOfOwner}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.name && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Name</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.name}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.companyName && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Company Name</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.companyName}</span>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Mobile Number</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{ParcelDetails?.mobileNumber},</span>{" "}
              <span>{ParcelDetails?.mobileNumber2},</span>{" "}
              <span>{ParcelDetails?.mobileNumber3}</span>{" "}
            </div>
          </div>
        </div>

        {ParcelDetails?.serialNumber && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Serial Number</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.serialNumber}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.voterNumber && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Voter Number</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.voterNumber}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.officeNumberInfo && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Office Number Info</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.officeNumberInfo}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.address && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Address</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.address}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.newAddress && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">New Address</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.newAddress}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.giftDelivered && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Gift Delivered</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.giftDelivered}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.createdAt && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Created At</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{moment(ParcelDetails?.createdAt).format("lll")}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.updatedAt && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Updated At</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{moment(ParcelDetails?.updatedAt).format("lll")}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.comment && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Comment</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.comment}</span>
              </div>
            </div>
          </div>
        )}

        {ParcelDetails?.zoneName && (
          <div className="flex flex-no-wrap py-2">
            <div className="w-2/5 flex-none">
              <div className="text-gray-500">
                <span className="font-bold">Zone Name</span>
              </div>
            </div>
            <div className="w-2/5 flex-none">
              <div className="text-gray-700">
                <span>{ParcelDetails?.zoneName}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SingleParcel;
