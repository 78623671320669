import {
	AutoComplete,
	Avatar,
	Button,
	Drawer,
	Empty,
	Form,
	Input,
	Rate,
	Select,
	Spin,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { authenticateToken } from '../../../utils/auth'
import axios from 'axios'
import {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { debounce } from 'lodash'
import BreadCrumb from '../../Layouts/Breadcrumb'
import AddBtn from '../../common/AddBtn'
import { DriverStatus, getPage, getParamValue } from '../../../utils'
import Loader from '../../common/Loader'
import AddDriver from './AddDriver'
import Pagination from '../../common/Pagination'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from '../../../styles/tailwind/List.module.css'
const { Option } = Select

const DeliveryHeroList = (): ReactElement => {
	const [form] = Form.useForm()
	const loc = useLocation()
	const page = getParamValue(loc.search, 'page')
	const [limit, setLimit] = useState(16)
	const [status, setStatus] = useState('')
	const [visible, setVisible] = useState<any>(undefined)
	const [showSearch, setShowSearch] = useState(false)
	const [selectedDriver, setSelectedDriver] = useState(undefined)
	const [mobileNumber, setMobileNumber] = useState('')
	const navigate = useNavigate()

	const [driverData, setDriverData] = useState<any>({
		loading: false,
		data: null,
	})

	const [driversOptions, setDriversOptions] = useState({
		loading: false,
		list: null,
	})

	const getDrivers = useCallback(async () => {
		setDriverData({ loading: true, data: null })

		const encodedUrl = `${process.env.REACT_APP_RIDER_API}`
		axios
			.get(
				`${encodedUrl}/admin/driver` +
					`?page=${page || 0}` +
					(limit ? `&limit=${limit}` : ``) +
					(status ? `&status=${status}` : ``) +
					(mobileNumber
						? `&mobileNumberOrName=${mobileNumber?.replace('+88', '')}`
						: ``),
				{
					headers: {
						Authorization: `Bearer ${authenticateToken()}`,
					},
				}
			)
			.then(res => {
				setDriverData({ loading: false, data: res.data })
			})
			.catch(err => {
				setDriverData({ loading: false, data: [] })
				console.error('Driver: Error', err)
			})
	}, [limit, page, mobileNumber, status])

	const getDriversOptions = useCallback(
		async (mobileNumber: string) => {
			setDriversOptions({ loading: true, list: null })
			const encodedUri = `${process.env.REACT_APP_RIDER_API}`
			axios
				.get(
					`${encodedUri}/admin/driver?` +
						(mobileNumber
							? `&mobileNumberOrName=${mobileNumber?.replace('+88', '')}`
							: ``) +
						(status ? `&status=${status}` : ``) +
						`&page=${0}` +
						`&limit=${20}`,
					{
						headers: {
							Authorization: `Bearer ${authenticateToken()}`,
						},
					}
				)
				.then(res => {
					setDriversOptions({
						loading: false,
						list: res.data?.drivers?.map((driver: any) => ({
							label: `${driver.mobileNumber.replace('+88', '')} - ${driver.name}`,
							value: driver.mobileNumber.replace('+88', ''),
						})),
					})
				})
				.catch(err => {
					setDriversOptions({ loading: false, list: null })
					console.error('Drivers: Error', err)
				})
		},
		[status]
	)

	const fetchRef = useRef<any>(null)
	const getDriversOptionsDebounce = useMemo(() => {
		const loadOptions = (value: string) => {
			fetchRef.current += 1
			const fetchId = fetchRef.current
			setDriversOptions({ loading: false, list: null })
			if (fetchId !== fetchRef.current) {
				return
			}
			getDriversOptions(value)
		}

		return debounce(loadOptions, 800)
	}, [getDriversOptions])

	useEffect(() => {
		getDrivers()
	}, [getDrivers])

	useEffect(() => {
		if (showSearch) getDriversOptions('')
	}, [showSearch])

	const onClose = () => {
		setSelectedDriver(undefined)
		setVisible(undefined)
	}

	const reseAllFieldData = () => {
		form.resetFields()
		setStatus('')
		setMobileNumber('')
	}

	return (
		<>
			<BreadCrumb
				title='Driver List'
				subTitle={`${driverData?.data?.totalElements} Driver(s)`}
				extra={[
					<Button
						type='dashed'
						shape='circle'
						onClick={() => setShowSearch(!showSearch)}
						key={1}
					>
						<SearchOutlined />
					</Button>,
					<AddBtn onClick={() => setVisible(true)} key={2} />,
				]}
			/>
			{showSearch && (
				<div className={styles.searchBox}>
					<Form form={form} layout='inline' className={styles.formInline}>
						<Form.Item name='mobile'>
							<AutoComplete
								dropdownClassName='certain-category-search-dropdown'
								dropdownMatchSelectWidth={250}
								style={{ width: 250 }}
								onSearch={getDriversOptionsDebounce}
								onSelect={(val: any) => setMobileNumber(val.toString())}
								options={driversOptions?.list || undefined}
								defaultActiveFirstOption={false}
								notFoundContent={driversOptions?.loading ? <Spin size='small' /> : null}
							>
								<Input.Search
									placeholder='Search by ID'
									onSearch={val => setMobileNumber(val)}
									enterButton
									loading={driversOptions.loading}
									size='large'
								/>
							</AutoComplete>
						</Form.Item>

						<Form.Item name='status'>
							<Select
								size='large'
								placeholder='Select Status'
								onChange={val => setStatus(val)}
							>
								<Option value=''>All</Option>
								{Object.values(DriverStatus).map((status, i) => (
									<Option key={i} value={status}>
										{status?.split('_').join(' ')}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Form>
					<Button type='primary' danger size='large' onClick={reseAllFieldData}>
						Reset
					</Button>
				</div>
			)}

			<div className={styles.contentWrapper}>
				<div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
						<div className={showSearch ? `content-body-withSearch` : `content-body`}>
							{driverData?.loading ? (
								<Loader />
							) : (
								<table className={styles.mainTable}>
									<thead className='bg-white border-b'>
										<tr>
											<th scope='col'>Driver</th>
											<th scope='col'>Contact Number</th>
											<th scope='col'>Balance</th>
											<th scope='col'>Rattings</th>
											<th scope='col'>Status</th>
										</tr>
									</thead>

									<tbody>
										{driverData?.data?.drivers?.length ? (
											driverData?.data?.drivers?.map((driver: any, index: any) => (
												<tr
													className='border-t hover:bg-gray-100'
													key={index}
													onClick={() => {
														// setSelectedDriver(driver)
														navigate(`/delivery/drivers/${driver?.id}/details`)
													}}
												>
													<td>
														<Avatar size={40} src={driver?.profilePictureUrl} />{' '}
														{driver?.name}
													</td>
													<td>{driver?.mobileNumber?.replace('+88', '')}</td>
													<td>&#2547;{driver?.balance}</td>
													<td>
														<Rate value={driver?.rating || 1} disabled />
														<small>({driver?.numberOfRating})</small>
													</td>
													<td>
														<span className='border p-1 px-5 rounded-2xl bg-white'>
															{driver?.status}
														</span>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td>
													<Empty />
												</td>
											</tr>
										)}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>

				<Pagination
					{...driverData?.data}
					limit={limit}
					page={getPage(loc.search)}
				/>
			</div>

			<Drawer
				title={selectedDriver ? 'Driver Details' : 'Add Driver'}
				placement='right'
				onClose={onClose}
				visible={selectedDriver || visible}
				width={500}
			>
				{/* {selectedDriver ? (
					<SingleDriver DriverDetails={selectedDriver} />
				) : ( */}
				<AddDriver DriverDetails={selectedDriver} onCloseMethod={onClose} />
				{/* )} */}
			</Drawer>
		</>
	)
}

export default DeliveryHeroList
