import {
	Button,
	Checkbox,
	Divider,
	Form,
	Input,
	Row,
	Col,
	Select,
	Radio,
} from 'antd'
import { Key, useCallback, useEffect, useState } from 'react'
import { authenticateToken } from '../../../utils/auth'
import { responseNotification } from '../../../utils/notify'
import styles from '../../../styles/tailwind/Add.module.css'
import { jcTypeArray } from '../../../utils'
import axios from 'axios'
import { useSelector } from 'react-redux'
import SubmitResetBtn from '../../common/SubmitBtn'
const CheckboxGroup = Checkbox.Group

const AddGroup = ({ visibleData, onCloseMethod }: any) => {
	const { type } = useSelector(state => (state as any)?.authReducer)
	const [error, setError] = useState('')
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [groupData, setGroupData] = useState<any>(undefined)
	// --------------------------------
	const [checkedList, setCheckedList] = useState<any>({})
	// --------------------------------

	const [serviceOptions, setServiceOptions] = useState<any>({
		loading: false,
		list: [],
	})

	const onSubmit = async (data: any) => {
		setLoading(true)

		const readyData = data && {
			name: data.name,
			type: data.type,
			serviceActions: Object.keys(checkedList)?.map(key => ({
				action: checkedList?.[key],
				serviceId: key,
			})),
		}

		if (groupData) {
			await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...readyData,
					id: groupData?.id,
					isActive: groupData?.isActive,
				}),
			})
				.then(res => res.json())
				.then(res => {
					setLoading(false)

					if (res.statusCode === 200) {
						responseNotification('Group Updated Successfully', 'success')
						form.resetFields()
						if (onCloseMethod) {
							onCloseMethod()
						}
					} else if (res.status === 500) {
						responseNotification('Internal server error', 'error')
					} else {
						responseNotification(res.message || 'something wrong', 'warning')
					}
				})
				.catch(err => {
					setLoading(false)
					responseNotification(`${'Internal server error'} ${err}`, 'error')
					console.error('err', err)
				})
		} else {
			await fetch(`${process.env.REACT_APP_AUTH_API}/group`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(readyData),
			})
				.then(res => res.json())
				.then(res => {
					setLoading(false)

					if (res.statusCode === 200) {
						responseNotification('Group Create Successfully', 'success')
						form.resetFields()
						if (onCloseMethod) {
							onCloseMethod()
						}
					} else if (res.status === 500) {
						responseNotification('Internal server error', 'error')
					} else {
						responseNotification(res.message || 'something wrong', 'warning')
					}
				})
				.catch(err => {
					setLoading(false)
					responseNotification(`${'Internal server error'} ${err}`, 'error')
					console.error('err', err)
				})
		}
	}

	const getServiceOptions = useCallback(
		async (key?: string) => {
			setServiceOptions({ loading: true, list: [] })
			const encodedUri = `${process.env.REACT_APP_AUTH_API}`
			return axios
				.get(
					`${encodedUri}/service/by-type` +
						(type ? `?Type=${type}` : ``) +
						(key ? `&name=${key}` : ``),
					{
						headers: {
							Authorization: `Bearer ${authenticateToken()}`,
						},
					}
				)
				.then(res => {
					setServiceOptions({
						loading: false,
						list: res.data?.services?.map((service: { name: any; id: any }) => ({
							label: service.name,
							value: service.id,
						})),
					})
				})
				.catch(err => {
					setServiceOptions({ loading: false, list: [] })
					console.error('Services: Error', err)
				})
		},
		[type]
	)

	const getServicesByGroup = useCallback(async (groupId?: string) => {
		setLoading(true)
		const encodedUri = `${process.env.REACT_APP_AUTH_API}`
		return axios
			.get(`${encodedUri}/service-group/services-by-group?groupId=${groupId}`, {
				headers: {
					Authorization: `Bearer ${authenticateToken()}`,
				},
			})
			.then((res: any) => {
				const temp = {} as any
				res?.data?.serviceGroups?.forEach((service: any) => {
					if (service?.serviceNameId) {
						temp[service?.serviceNameId] = service?.action
					}
				})
				setCheckedList(temp)
				setLoading(false)
			})
			.catch((err: any) => {
				setLoading(false)
				console.error('Services: Error', err)
			})
	}, [])

	const onChange = (list: any) => {
		// console.log("list", list);
		// setCheckedList(list);
		// setIndeterminate(
		//   !!list.length && list.length < serviceOptions?.list?.length
		// );
		// setCheckAll(list.length === serviceOptions?.list?.length);
		try {
			const temp = {} as any
			list?.forEach((id: any) => {
				if (checkedList?.[id]) {
					temp[id] = checkedList?.[id]
				} else {
					temp[id] = 'all'
				}
			})

			setCheckedList(temp)
		} catch (e) {
			console.log(e)
		}
	}

	const onChangeAction = (id: string, value: any) => {
		const temp = { ...checkedList }
		temp[id] = value
		setCheckedList(temp)
	}

	useEffect(() => {
		if (visibleData) {
			setGroupData(visibleData)
			getServicesByGroup(visibleData?.id)
		}
	}, [getServicesByGroup, visibleData])

	useEffect(() => {
		if (groupData) {
			form.resetFields(Object.keys(visibleData))
		}
	}, [form, groupData, visibleData])

	useEffect(() => {
		getServiceOptions()
	}, [getServiceOptions])

	console.log('List:', checkedList)

	return (
		<>
			<Form
				name='control-hooks'
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				className='ant-form ant-form-vertical'
				onFinish={onSubmit}
				form={form} // like ref
				layout='vertical'
				initialValues={{
					...groupData,
				}}
			>
				<Form.Item
					hasFeedback
					label='Group Name'
					rules={[
						{
							required: true,
							message: 'Name is Required!',
						},
					]}
					name='name'
				>
					<Input id='name' size='large' type='text' placeholder='Enter Type Name' />
				</Form.Item>

				{/* <Form.Item
					hasFeedback
					label='Type'
					rules={[
						{
							required: true,
							message: 'Type is required!',
						},
					]}
					name='type'
				>
					<Select
						placeholder='Group Type'
						style={{ minWidth: 300 }}
						onChange={val => setType(val)}
						size='large'
					>
						{Object.values(jcTypeArray)?.map((type, i) => (
							<Select.Option value={type} key={i}>
								{type?.replace('_', ' ')}
							</Select.Option>
						))}
					</Select>
				</Form.Item> */}

				<>
					{/* <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={
              checkAll ||
              selectedProductBarcode?.length === checkedList?.length
            }
            className="mr-3"
          >
            Check all
          </Checkbox>
          <Divider /> */}

					<CheckboxGroup
						style={{ width: '100%' }}
						onChange={onChange}
						value={Object.keys(checkedList)}
					>
						{serviceOptions?.list?.length
							? serviceOptions?.list?.map(
									(
										serviceGroup: { value: string; label: string },
										i: Key | null | undefined
									) => (
										<Row key={i}>
											<Col span='14'>
												<Checkbox
													value={serviceGroup?.value}
													checked={!!checkedList?.[serviceGroup.value]}
												>
													{serviceGroup?.label}
												</Checkbox>
											</Col>
											<Col span='10'>
												<Radio.Group
													onChange={e =>
														onChangeAction(serviceGroup?.value, e.target?.value)
													}
													value={checkedList[serviceGroup?.value]}
												>
													<Radio value={'all'}>All</Radio>
													<Radio value={'view'}>View</Radio>
												</Radio.Group>
											</Col>
										</Row>
									)
							  )
							: undefined}
					</CheckboxGroup>
				</>

				<SubmitResetBtn
					onClickReset={() => {
						form?.resetFields()
					}}
					disabled={loading || !!error}
					loading={loading}
				/>
			</Form>
		</>
	)
}

export default AddGroup
