import { ReactElement } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import NotFoundImg from '../../images/404.webp'

const NotFound = (): ReactElement => {
	const navigate = useNavigate()
	return (
		<>
			<section className='flex items-center h-screen w-screen p-16 dark:bg-red-100 dark:text-gray-100'>
				<div className='container flex flex-col items-center justify-center px-5 mx-auto my-8'>
					<div className='max-w-md text-center'>
						{/* <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
              <span className="sr-only">Error</span>404
            </h2> */}
						<img src={NotFoundImg} alt='404' />
						<p className='text-2xl md:text-2xl text-red-600 font-bold'>
							Opps, we couldn&apos;t find this page.
						</p>
						<p className='mt-4 mb-8 dark:text-gray-600'>
							But don&apos;t worry, you can find plenty of other things on our
							homepage.
						</p>
						<Link
							to=''
							replace={true}
							onClick={() => navigate(-1)}
							className='px-10 py-3 font-semibold rounded shadow-lg dark:bg-red-400 dark:text-light-900 hover:text-white hover:bg-red-500'
						>
							Go Back
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}

export default NotFound
